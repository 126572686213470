/* src/pages/About.css */
@import '../styles/variables.css';
@import '../styles/fonts.css';

.introductionContent a{
  color:var(--accent-color);
  text-decoration: none;
}

#marvinSection {
  display: flex;
  justify-content: center;
  margin: 40px;
  
}
#marvinSection .content {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1170px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 40px;
    gap: 20px;
}
.marvin-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.text-wrap {
  flex: 1;
  display: flex;
  height: 350px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 767px) {
  .text-wrap {
      margin-top: -120px;
  }
}

.text-wrap:before {
  content: '';
  position: absolute;
  top: 0px;
  height: 100px;
  width: 100%;
  z-index: 30;
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.text-wrap p.marvin-text {
  position: absolute;
  width: 100%;
  bottom: 0px;
  white-space: pre-line;
  text-align: center;
  font-size: 16px;
  line-height: 1.6;
  font-family: 'digital_dream_fatregular', sans-serif;
  color: #4EA557;
  text-shadow: 0px 0px 10px rgba(78, 165, 87, 1);
}

@media (min-width: 768px) {
  .text-wrap p.marvin-text {
      font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .text-wrap p.marvin-text {
      font-size: 21px;
  }
}
