/* src/components/videPlayer.css */
@import '../styles/variables.css';

.videoPlayer {
    display: flex;
    margin-bottom: 40px;
    width: 100%;
    height: 100%;
    position: relative;
}
.videoPlayer .react-player__preview{
    aspect-ratio: 16 / 9;
}

/* src/components/CustomPlayer.css */
.playerWrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
  }
  .posterWrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    background-color: var(--black);
  }

  .posterImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    opacity: 0.8;
  }

  .playButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 10px solid rgba(255, 255, 255, 0.75);
    background-color: rgba(0, 0, 0, .2);
    border-radius: 50%;
    width: 200px;
    height: 200px;
    cursor: pointer;
    z-index: 2;
    font-size: 100px;
  }

  .playIcon {
    color: var(--white);
    display: inline-flex;
    align-items: center;
    height: 1em;
    width: auto;
    margin-left: 10%;
  }

  
 /* .poster-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
  }
  
  .poster-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .play-button {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 20px;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  */