@import '../styles/variables.css';
@import '../styles/fonts.css';

.cookies {
  display: flex;
  justify-content: center;
  margin: 40px;
}
.cookies .container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1170px;
}

.cookies h4 {
  color: var(--body-text-color);
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 20px 0 10px 0;
}

.cookies p + h4{
  margin-top: 0px;
}

.cookies h5 {
color: var(--body-text-color);
font-size: 1.1rem;
line-height: 1.2;
font-weight: 00;
margin: 10px 0;
}

.cookies p {
  color: var(--body-text-color);
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  margin: 0 0 20px 0;
}

.cookies ol, .cookies ul{
  color: var(--body-text-color);
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  margin: 0 0 20px 0;
}

.cookies ol li, .cookies ul li{
  margin: 0 0 20px 0;
}


.cookies ol li:last-child, .cookies ul li:last-child{
margin-bottom: 0px;
}

.bold {
  font-weight: 700;
}
