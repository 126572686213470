@font-face {
  font-family: 'digital_dream_fatregular';
  src: url('../fonts/DIGITALDREAMFAT-webfont.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DIGITALDREAMFAT-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/DIGITALDREAMFAT-webfont.woff') format('woff'), /* Modern Browsers */
       url('../fonts/DIGITALDREAMFAT-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/DIGITALDREAMFAT-webfont.svg#digital_dream_fatregular') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
