/* src/index.css */

@import '../styles/variables.css';

.breadcrumb {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumb-item {
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 1.80px;
    word-wrap: break-word;
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: var(--secondary-color);
    
  }
  
  .breadcrumb-item a:hover {
    font-weight: bold;
    color: var(--link-color-hover);
  }
  
  .breadcrumb-item.active {
    color: var(--secondary-color);
  }

  .breadcrumb-divider {
    font-size: 1rem;
    line-height: 1rem;
    color: var(--secondary-color);
    margin: 0 8px;
  }
  
  @media (max-width: 576px) {
    .breadcrumb-item {
      letter-spacing: 1px;
      font-size: 0.875rem;
      line-height: 0.875rem;
    }
    .breadcrumb-divider {
      font-size: 0.875rem;
      line-height: 0.875rem;
    }
  }