/* Base button styles */
@import '../styles/variables.css';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 1em;
  border-radius: 50px;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease; /* Add transition for smooth effect */
}

.button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Button types */
.button-primary {
  background-color: var(--button-primary-color);
  color: var(--button-label-color);
}
.button-secondary {
  background-color: #6c757d;
  color: white;
}
.button-tertiary {
  background-color: #f8f9fa;
  color: #6c757d;
}
.button-destructive {
  background-color: #dc3545;
  color: white;
}
.button-outline {
  background-color: transparent;
  border: 1px solid currentColor;
}

.button-primaryOutline {
  background-color: var(--accent-color);
  border: 1px solid var(--white);
  color: var(--white);
}

.button-link {
  background-color: transparent;
  color: var(--body-color);
  text-transform: none;
}

.button-link:hover {
  text-decoration: underline;
}

.button-header {
  background-color: transparent;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
  font-size: 1.5rem;
  padding: 0.5em 0.5em;
}

.button-header:hover {
  text-decoration: underline;
  color: var(--body-color);
}

.button-header svg {
  height: 32px;
  width: auto;
  margin: 0;
}

/* Button sizes */
.button-small {
  padding: 0.25em 0.5em;
  font-size: 0.875rem;
}

.button-default {
  padding: 0.5em 1em;
  font-size: 1rem;
}

.button-large {
  padding: 1em 1.5em;
  font-size: 1rem;
}

/* Icon styles */
.button-icon {
  display: inline-flex;
  align-items: center;
  height: 1em;
  width: auto;
}
.button-icon-start {
  margin-right: 0.5em;
}
.button-icon-end {
  margin-left: 0.5em;
}

.button-small .button-icon{
  height: 1em;
  width: auto;
}

.button-large .button-icon {
  height: 1.2em;
  width: auto;
}

@media (max-width: 576px) {
  .button-header {
    background-color: transparent;
    width: 48px;
    height: 48px;
    padding: 0px;
  }
  .button-header svg {
    height: 24px;
  }
}
