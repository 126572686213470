/* src/components/Skills.css */
.skills {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 40px;
  }
  
  .skills-title {
    width: 100%;
    border-bottom: 1px solid var(--border-color);
  }
  
  .skills-list {
    font-size: 1rem;
  }
  