@import '../styles/variables.css';

.cookieConsent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--accent-color);
}

.cookieConsent .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    max-width: 1170px;
    margin: 20px;
}

.cookieConsent .container p {
    color: var(--white);
    margin: 0;
    line-height: 1.2;
}

.cookieConsent .container a {
    color: var(--white);
    font-weight: 700;
}

@media (max-width: 576px) {
    .cookieConsent .container {
        flex-direction: column;
        gap: 10px;
    }
    .cookieConsent .container p {
        text-align: center;
    }
}