@import '../styles/variables.css';

.maskLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--page-color);
  width: 50%;
  height: 30px;
  transform: skew(50deg);
  transform-origin: bottom left;
  z-index: 3;
}
.maskRight {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--page-color);
  width: 50%;
  height: 30px;
  transform: skew(-50deg);
  transform-origin: bottom left;
  z-index: 3;
}

.hero-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  overflow: hidden;
}

.hero-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-top: 30px solid var(--page-color);
}

.hero-images-wrapper {
  display: flex;
  width: 100%;
  height: 500px;
  gap: 1px;
}

.hero-image {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-left, .hero-right {
  width: 50%;
  position: relative;
  overflow: hidden;
}

.hero-right-multi {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1px;
}

.image-container {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.single-image .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-gallery-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.swiper-gallery-overlay.active {
  display: flex;
}

.hero-section .swiper-slide > img, .hero-section .swiper-zoom-container > img {
  max-width: calc(100% - 100px);
  max-height: calc(100% - 100px);
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.single-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 576px) {
  .hero-section {
    margin-bottom: 0px;
  }
  .hero-images-wrapper {
    height: 260px;
  }
  .hero-left {
    width: 100%;
  }
  .hero-right, .hero-right-multi {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero-right, .hero-right-multi {
    display: none;
  }


  .hero-section .swiper-slide img, .hero-section .swiper-slide .swiper-zoom-container img{
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
  }
}