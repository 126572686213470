@import '../styles/variables.css';

.example-gallery {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.gallery-grid {
  display: grid;
  gap: 10px;
}

.gallery-grid.single-image .gallery-item {
  width: 100%;
}

.gallery-grid.two-images {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 380px;
}

.gallery-grid:not(.single-image):not(.two-images) {
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
}

.gallery-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-radius: 3px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-gallery-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.swiper-pagination-bullet {
  background-color: white;
  opacity: 0.5;
}
.swiper-pagination-bullet-active {
  background-color: var(--accent-color);
  opacity: 1;
}

.swiper-wrapper {
  /*width: calc(100% - 100px);
  height: calc(100% - 100px);*/
  height: 98vh;
}

.swiper-slide, .swiper-zoom-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.example-gallery .swiper-slide > img, .example-gallery .swiper-zoom-container > img {
  max-width: calc(100% - 100px);
  max-height: calc(100% - 100px);
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 30px;
  color: white;
  cursor: pointer;
  z-index: 1001;
}

.swiper-button-prev, .swiper-button-next {
  color: white;
}

@media (max-width: 768px) {
  .gallery-grid.two-images {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .swiper-container {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }

  .swiper-slide img, .swiper-slide .swiper-zoom-container img{
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
  }
}
