@import '../styles/variables.css';

.related {
    display: flex;
    flex-direction: column;
}

.relatedHeader {
    display: flex;
    width: 50%;
    height: 60px;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--banner-grey);
}

.relatedHeader h3 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    margin: 0 10px;
}

.scrollIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: var(--accent-color);
    font-size: 24px;
}

.relatedExamplesContainer {
    width: 100%;
}
.relatedExamplesContainer .swiper-wrapper {
    width: 100%;
    height: 100%;
  }
  
.relatedExampleThumb {
    display: flex;
    width: 100%;
    position: relative;
    height: 275px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.relatedExampleThumb a {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    text-decoration: none;
    color: var(--page-color);
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    /*background: linear-gradient(0px, var(--black), transparent);*/
}

.relatedExampleThumb a:hover {
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s;
}

.relatedExampleThumb a .relatedExampleInfo {
    display: flex;
    flex-direction: column;
    padding: 20px;
    opacity: 0;
    transition: opacity 1s;
    text-decoration: none;
}

.relatedExampleThumb a:hover .relatedExampleInfo {
    opacity: 1;
}

h3.relatedExampleTitle {
    font-size: 1.8rem;
    line-height: 100%;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.relatedExampleCategories {
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.relatedExampleThumb a:hover {
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.5s;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .relatedHeader {
    width: 100%;
  }
  .relatedHeader h3 {
    font-size: 1.8rem;
  }
}

.touch-device .relatedExampleThumb a {
    background: linear-gradient(transparent, transparent ,var(--black));
}

.touch-device .relatedExampleThumb a .relatedExampleInfo {
    opacity: 1;
}

@media (max-width: 768px) {
    .relatedExampleThumb a{
      background: linear-gradient(transparent, transparent ,var(--black));
    }
    .relatedExampleThumb a .relatedExampleInfo {
      opacity: 1;
    }
  }
  