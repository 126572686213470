/* index.css */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif; /* Ensure a consistent font family */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}