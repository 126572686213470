/* src/pages/About.css */
@import '../styles/variables.css';

#about {
    display: flex;
    justify-content: center;
    margin: 120px 40px 40px 40px;
}
#about .content {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1170px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 40px;
    gap: 20px;

}

#about .content::before {
    content: 'About me';
    position: absolute;
    left: -40px;
    top: -40px;
    display: flex;
    position: absolute;
    font-size: clamp(60px, 16vw, 190px);
    letter-spacing: 7.2px;
    line-height: 171px;
    text-transform: uppercase;
    font-weight: 900;
    color: #555;
    opacity: 0.1;
    z-index: -1;
}

.profilePicture {
    width: 350px;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    border: 1px solid var(--border-color);
}

.aboutMe {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
    max-width: 1440px;
}

.aboutMe h2 {
    display: block;
    font-size: 3rem;
    line-height: 1.4;
    font-weight: 400;
    color: var(--body-color);
    margin: 0;
}

.aboutMe h2::after {
    content: '';
    position: relative;
    display: block;
    width: 50px;
    height: 3px;
    background-color: var(--accent-color);
    margin: 10px 0 20px 0;
}

.aboutMe h3 {
    color: var(--body-text-color);
    font-size: 2rem;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 20px 0 10px 0;
}

.aboutMe h4 {
    color: var(--body-text-color);
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 700;
    margin: 10px 0;
  }

.aboutMe p {
    font-size: 1.2rem;
    line-height: 2;
    margin: 0 0 20px 0;
    padding: 0;
}

.aboutMe p + h4{
    margin-top: 0px;
}

.aboutMe ol, .aboutMe ul{
    color: var(--body-text-color);
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
    margin: 0 0 20px 0;
}

.aboutMe ol li, .aboutMe ul li{
    margin: 0 0 20px 0;
}

.introduction{
    margin: auto;
    max-width: 1170px;
    padding: 0px 40px;
}

.contactDetails {
    display: flex;
    max-width: 1170px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
    gap: 20px;
}
@media (max-width: 576px) {
    .contactDetails {
        margin: 20px;
    }
}

.contactDetails h3 {
    color: var(--body-text-color);
    font-size: 2rem;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 20px 0 10px 0;
}

.contactDetails p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    text-align: center;
}


.button-link {
    font-size: 1.5rem;
}
