@import '../styles/variables.css';

.header {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--page-color);
  border-bottom: 1px solid var(--border-color);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
  transition: all 0.3s ease; /* Add transition for smooth effect */
}

.header.scrolled {

  height: 48px;
  background-color: var(--page-color);
  border-bottom: 1px solid var(--border-color);
}
.header.scrolled .logoIcon {
  height: 24px;
}
.header.scrolled button {
  height: 48px;
  width: 48px;
  padding: 0.7em;
}

.header h1 {
  display: flex;
  margin: 0;
}

.logoIcon {
  display: flex;
  position: relative;
  height: 38px;
  width: auto;
  padding-left: 10px;
  cursor: pointer;
  transition: all 0.3s ease; /* Add transition for smooth effect */
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.nav-buttons {
  display: flex;
  gap: 0px;
}


@media (max-width: 576px) {
  .header {
    height: 48px;
    border: none;
  }
  .logoIcon {
    height: 24px;
  }
  .header button {
    height: 48px;
    width: 48px;
    padding: 0.7em;
  }
}


/* Medium Devices (Tablets, Larger tablets) */
@media (max-width: 992px) {
  /* Your styles for medium devices */
}