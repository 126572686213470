/* src/component/PortfolioSection.css */
@import '../styles/variables.css';

.portfolio {
    padding:0;
    margin: 0;
  }
  
  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 0.35fr));
    gap: 0;
  }

  .example {
    display: flex;
    justify-content: center;
    margin: 40px;
  }
  .example .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1170px;
  }

.example h4 {
    color: var(--body-text-color);
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: 700;
    letter-spacing: 2px;
    margin: 20px 0 10px 0;
}

.example p + h4{
    margin-top: 0px;
}

.example h5 {
  color: var(--body-text-color);
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: 00;
  margin: 10px 0;
}

.example p {
    color: var(--body-text-color);
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
    margin: 0 0 20px 0;
}

.example ol, .example ul{
    color: var(--body-text-color);
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
    margin: 0 0 20px 0;
}

.example ol li, .example ul li{
    margin: 0 0 20px 0;
}


.example ol li:last-child, .example ul li:last-child{
  margin-bottom: 0px;
}

.bold {
    font-weight: 700;
}


@media (max-width: 576px) {
  .portfolio-grid {
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    gap: 0;
  }
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  .example {
    margin: 20px;
  }
}


@media (min-width: 1440px) {
  .portfolio-grid {
    grid-template-columns: repeat(auto-fit, minmax(420px, 0.35fr));
  }
}

@media (min-width: 1920px) {
  .portfolio-grid {
    grid-template-columns: repeat(auto-fit, minmax(480px, 0.35fr));
  }
}


