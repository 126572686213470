/* src/styles/variables.css */
:root {
    --accent-color: #E88400;
    --page-color: #ffffff;
    --banner-grey: #E9E9E9;
    --body-color: #555555;
    --secondary-color: #777777;
    --link-color-hover: #E88400;
    --button-primary-color: #E88400;
    --button-label-color: #ffffff;
    --border-color: #dddddd;
    --black: #333333;
    --white: #ffffff;
  }