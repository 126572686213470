/* src/pages/Home.css */
@import '../styles/variables.css';

.introduction{
    margin: auto;
    max-width: 1170px;
    padding: 0px 40px;
}

.introductionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.introductionContent p {
    font-size: 1.3rem;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    text-align: center;
}




@media (max-width: 576px) {
    #about {
        margin: 20px;
    }
    #about .content {
        flex-direction: column;
        align-items: flex-start;
    }
    #about .content::before {
        display: none;
    }
    .aboutMe h3 {
        font-size: 2rem;

    }

    .introduction{
        padding: 0px 20px;
    }
    .introductionContent {
        margin-bottom: 20px;
    }

    .introductionContent p {
        font-size: 1rem;
        line-height: 1.2;
        text-align: left;
    }


  }