@import '../styles/variables.css';

.menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 40px;
    text-align: center;
  }
  
  .menu li {

  }
  
  .menu a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 400;
  }
  