/* src/components/PortfolioItem.css */

@import '../styles/variables.css';

.exampleThumb {
    display: flex;
    width: 100%;
    position: relative;
    height: 275px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }

  .exampleThumb a {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    text-decoration: none;
    color: var(--page-color);
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
  }

  .exampleThumb a:hover {
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.5s;
  }


  .exampleThumb a .exampleInfo {
    display: flex;
    flex-direction: column;
    padding: 20px;
    opacity: 0;
    transition: opacity 1s;
    text-decoration: none;
  }

  .exampleThumb a:hover .exampleInfo {
    opacity: 1;
  }

  h3.exampleTitle{
    font-size: 2rem;
    line-height: 100%;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }

  .exampleCategories {
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }

  @media (min-width: 1920px) {
    .exampleThumb {
      height: 350px;
    }
  }

  .touch-device .exampleThumb a {
    background: linear-gradient(transparent, transparent ,var(--black));
  }

  .touch-device .exampleThumb a .exampleInfo {
    opacity: 1;
  }

  @media (max-width: 768px) {
    .exampleThumb a {
      background: linear-gradient(transparent, transparent ,var(--black));
    }
    .exampleThumb a .exampleInfo {
      opacity: 1;
    }
  }
  