/* src/Footer.css */
@import '../styles/variables.css';

footer {
    padding: 20px;
    text-align: center;
    color: var(--secondary-color);
  }

  a {
    color: var(--secondary-color);
  }