/* src/components/StillInterested.css */
@import '../styles/variables.css';

.stillInterested {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
}

.bannerSection {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--banner-grey);
    color: var(--body-color);
    margin-bottom: 40px;
}

.bannerSection .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px;
    text-align: center;
}

.bannerSection::after {
    content: '';
    position: absolute;
    bottom: -30px; /* Adjust based on your design */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 30px solid var(--banner-grey); /* Triangle color */
}

.bannerSection .content h3 {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
}

.bannerSection .content p {
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 0;
    padding: 0;

}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin: 10px 0 30px 0px;
}

