/* src/App.css or any other CSS file */
@import './styles/variables.css';

body {
  font-family: 'Cairo', sans-serif;
  background-color: var(--page-color);
  color: var(--body-color);
  font-size: 16px;
}

/* App.css */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App > * {
  flex: 1;
}

.page {
  position: relative;
  z-index: 1;
  background-color: var(--page-color);
  transition: transform 0.5s ease;
  overflow-x: hidden;
}

/* Styles when menu is open */
.App.menu-open .page {
  transform: translate(-260px, 100px);
  border-radius: 30px;
}




