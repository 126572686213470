@import '../styles/variables.css';

.pageHeader {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 120px 0 80px 0;
}
.pageHeader.left {
    justify-content: center;
    align-items: center;
}

.pageTitle, .sectionTitle {
    display: flex;
    font-size: 3rem;
    line-height: 100%;
    font-weight: 400;
    color: var(--body-color);
    margin: 0;
    text-align: center;
}

.underline {
    display: flex;
    width: 60px;
    height: 4px;
    background-color: var(--accent-color);
    margin-top: 10px;
}

.pageSubtitle {
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    color: var(--secondary-color);
    margin: 0;
    padding: 0;
}

.backgroundText {
    display: flex;
    position: absolute;
    font-size: clamp(60px, 16vw, 190px);
    letter-spacing: 7.2px;
    line-height: clamp(60px, 16vw, 171px);
    text-transform: uppercase;
    font-weight: 900;
    color: #555;
    opacity: 0.1;
    z-index: -1;
    text-align: center;
}

@media (max-width: 576px) {
    .pageHeader {
        margin: 80px 0 40px 0;
    }
    .pageTitle {
        font-size: 1.8rem;
    }
    .pageSubtitle {
        font-size: 0.875rem;
    }
    .underline {
        display: none;
    }


  }